<template>
  <div>
    <div class="c22 c27 doc-content">
      <p class="c25 title">
        <span class="c8">Gap Selling Online Training Subscription Agreement</span>
      </p>
      <p class="c7">
        <span class="c0 c22">A Sales Growth Company, LLC, a Colorado limited liability company (&ldquo;</span><span
          class="c8 c22">ASG</span><span class="c0 c22">&rdquo;), with offices located at 1023 Walnut St #100, Boulder,
          CO 80302 </span><span class="c0">and/or its related, parent, affiliated or subsidiary companies owns and/or
          operates the
          internet site located at www.gapsellingonlinetraining.com, along with our related
          websites, hosted applications, mobile or other downloadable applications (collectively,
          the &ldquo;</span><span class="c8">Site</span><span class="c0">&rdquo;). This Gap Selling Online Training
          Subscription Agreement (this &ldquo;</span><span class="c8">Agreement</span><span class="c0">&rdquo;) sets out
          the terms of service applicable to our Site and the Subscription
          Services (defined in Section 1.b below). This Agreement is a legally binding contract
          between you and ASG regarding your use of the Subscription Services.</span>
      </p>
      <p class="c25 title"><span class="c8">PLEASE READ THE FOLLOWING TERMS CAREFULLY</span></p>
      <p class="c7">
        <span class="c0">BY CREATING AN ACCOUNT ON THE SITE (&ldquo;</span><span class="c8">ACCOUNT</span><span
          class="c0">&rdquo;) OR DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SUBSCRIPTION
          SERVICE, THE INDIVIDUAL OR ENTITY ENTERING THIS AGREEMENT (&ldquo;</span><span class="c8">YOU</span><span
          class="c0">&rdquo; OR &ldquo;</span><span class="c8">YOUR</span><span class="c0">&rdquo;) AGREE THAT YOU HAVE
          READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE
          SUBSCRIPTION SERVICE, YOU AGREE TO BE BOUND BY, THIS AGREEMENT, INCLUDING THE PRIVACY
          POLICY FOR THE SUBSCRIPTION SERVICE (THE &ldquo;</span><span class="c8">PRIVACY POLICY</span><span
          class="c0">&rdquo;) AND OTHER POLICIES AND ADDITIONAL TERMS WE REFERENCE BELOW. CERTAIN SERVICES,
          SOME OF WHICH MAY BE PROVIDED BY THIRD PARTIES, MAY BE SUBJECT TO ADDITIONAL TERMS AND
          CONDITIONS (COLLECTIVELY, &ldquo;</span><span class="c8">ADDITIONAL TERMS</span><span class="c0">&rdquo;), AND
          YOUR USE OF THE SITE AND SUCH SERVICES CONSTITUTES YOUR AGREEMENT TO ALL
          APPLICABLE ADDITIONAL TERMS. IF YOU ARE NOT ELIGIBLE TO USE THE SUBSCRIPTION SERVICE, OR
          DO NOT AGREE TO THIS AGREEMENT, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE
          SUBSCRIPTION SERVICE. YOUR USE OF THE SUBSCRIPTION SERVICE, AND ASG&rsquo;S PROVISION OF
          THE SUBSCRIPTION SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY AND BETWEEN ASG AND YOU TO BE
          BOUND BY THIS AGREEMENT.
        </span>
      </p>
      <p class="c7">
        <span class="c0">If you are an entity, organization, or company, the individual accepting this Agreement
          on your behalf represents and warrants that they have the authority to bind you to this
          Agreement and you agree to be bound by this Agreement.
        </span>
      </p>
      <ol class="c4 lst-kix_list_19-0 start" start="1">
        <li class="c16 li-bullet-0" id="h.gjdgxs"><span class="c8">General.</span></li>
      </ol>
      <ol class="c4 lst-kix_list_19-1 start" start="1">
        <li class="c5 li-bullet-0" id="h.30j0zll">
          <span class="c0">ASG has developed and owns the proprietary Gap Selling Program, including the Gap
            Selling Training courses, methods, documents, guides, exams, and other training
            information, content, and materials (the &ldquo;</span><span class="c8">Gap Selling Program</span><span
            class="c0">&rdquo;). ASG and its trainers sell and perform Gap Selling Program sessions to
            individuals, teams, organizations, and professional audiences (&ldquo;</span><span class="c8">Gap Selling
            Training(s)</span><span class="c0">&rdquo;). </span>
        </li>
        <li class="c5 li-bullet-0" id="h.1fob9te">
          <span class="c0">Through the Site, and subject to the terms of this Agreement, you can access (i) the
            Gap Selling Trainings, (ii) certain relevant products in the Gap Selling Program (and if
            applicable third-party software) on a subscription basis, and (iii) the provision of
            third-party services (collectively, the &ldquo;</span><span class="c8">Subscription Services</span><span
            class="c0">&rdquo;).</span>
        </li>
        <li class="c5 li-bullet-0" id="h.3znysh7">
          <span class="c0">For clarity, and notwithstanding anything to the contrary herein, nothing in this
            Agreement shall limit ASG&rsquo;s right to, in its sole discretion, directly or
            indirectly through other representatives or certified trainers, market, solicit for
            sale, sell, perform, or otherwise exploit the Gap Selling Program and Gap Selling
            Trainings to any third-party.
          </span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-0" start="2">
        <li class="c16 li-bullet-0" id="h.2et92p0">
          <span class="c8">Use of the Subscription Services and Related Products. </span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-1 start" start="1">
        <li class="c5 li-bullet-0" id="h.tyjcwt">
          <span class="c0">Subject to the terms of this Agreement, including your timely payment of the
            Subscription Fees (as defined in Section 7.a), ASG:</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-2 start" start="1">
        <li class="c21 li-bullet-0" id="h.3dy6vkm">
          <span class="c0">hereby grants you a limited, personal, non-exclusive, revocable, non-transferable,
            non-sublicenseable right, on a subscription basis, to access and use the Subscription
            Services during the Subscription Term (as defined in Section 8.a) depending on the
            Subscription Plan (defined in Section 7) that you select; and
          </span>
        </li>
        <li class="c21 li-bullet-0">
          <span class="c0">shall use reasonable efforts to make the Subscription Services available to you for the
            duration of the Subscription Term, except during any reasonable periods of emergency or
            preventive maintenance.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-1" start="2">
        <li class="c5 li-bullet-0" id="h.4d34og8">
          <span class="c0">You acknowledge and agree that all right, title, and interest in and to the Site,
            Subscription Services, Gap Selling Trainings (including the training materials), all ASG
            trademarks, service marks, brand names, logos, domain names, websites, works of
            authorship, trade secrets, know-how, and other intellectual property, information, or
            materials that are provided or made available to you by or on behalf of ASG, as well as
            any derivative works, modifications, or improvements thereof or relating thereto,
            whether by ASG, you, or a third-party, including all intellectual property rights
            therein (collectively, &ldquo;</span><span class="c8">ASG IP</span><span class="c0">&rdquo;), are solely and
            exclusively owned by and shall remain with ASG. If you acquire
            any rights in any ASG IP by operation of law or otherwise, you hereby irrevocably assign
            such rights to ASG without further action by either party. You agree not to dispute or
            challenge or assist any person or entity in disputing or challenging ASG&rsquo;s rights
            in and to any ASG IP</span><a id="id.1t3h5sf"></a><span class="c0">. You shall not acquire any ownership
            interest in or to any ASG IP under this
            Agreement</span><a id="id.2s8eyo1"></a><span class="c0">, and any goodwill derived from your use of any ASG
            IP shall inure to the benefit of
            ASG.</span>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c0">You shall comply with all applicable laws and regulations when using the Subscription
            Services.</span>
        </li>
        <li class="c5 li-bullet-0" id="h.17dp8vu">
          <span class="c0">By using the Subscription Services, you agree to: complete all assessments, problems,
            homework and assignments with your own work (unless collaboration is expressly
            permitted); acknowledge any and all external sources used in your work (including
            artificial intelligence); refrain from any activity that would dishonestly or
            fraudulently improve your results or disadvantage others in the course; accurately
            represent your work if you use or discuss it outside of the Subscription Services; be a
            responsible participant and communicate with respect to other participants in any
            discussion or feedback forum; and not access or attempt to access any other user&rsquo;s
            Account, or misrepresent or attempt to misrepresent your identity while using the
            Subscription Services. This Section 2.d is not intended to prohibit discussion of course
            material. While you must submit work that is your own, you should feel free to discuss
            course topics or other course material with other participants. If you use the
            Subscription Services in a manner that is inconsistent with this Section 2.d we may
            suspend or terminate your Account.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-0" start="3">
        <li class="c16 li-bullet-0" id="h.3rdcrjn">
          <span class="c8">Restrictions. </span><span class="c0">You shall not use the Subscription Services or any
            other ASG IP in any manner not
            expressly permitted in Section 2.a.i. Without limiting the foregoing, you shall not,
            directly or indirectly:</span><span class="c8">&nbsp;</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-1 start" start="1">
        <li class="c5 li-bullet-0">
          <span class="c0">resell, modify, alter, disassemble, decompile, reverse-engineer, produce derivative
            works of, or otherwise change the Subscription Services or other ASG IP;</span>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c0">exploit the Subscription Services or any other ASG IP, in any way that infringes upon
            the intellectual property rights of ASG, or which violates the terms of this Agreement
            or applicable law;</span><a id="id.26in1rg"></a>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c0">engage in any action that tends to disparage, dilute the value of, or reflect
            negatively on ASG, the Subscription Services, the Gap Selling Program, or any ASG IP; </span><a
            id="id.lnxbz9"></a>
        </li>
        <li class="c5 li-bullet-0" id="h.35nkun2">
          <span class="c0">use any ASG IP for use as a domain name, except with the prior express written
            authorization of ASG; or</span>
        </li>
        <li class="c5 li-bullet-0" id="h.1ksv4uv">
          <span class="c0">alter, obscure, or remove any proprietary rights notices placed on any component of the
            Subscription Services or other ASG IP that ASG may provide from time to time.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-0" start="4">
        <li class="c16 li-bullet-0">
          <span class="c8">Eligibility. </span><span class="c0">People under the age of 18 are not eligible to use the
            Site or any Subscription
            Services in any way. You affirm that you are over 18 years of age. If you are under 18
            years of age, do not use the Subscription Services. If ASG learns that you are under 18
            and your personally identifiable information has been collected, then ASG will delete
            your Account and all personal information associated with it. In addition, certain Gap
            Selling Trainings may have additional eligibility requirements, as specified on the
            applicable Site.</span>
        </li>
        <li class="c16 li-bullet-0">
          <span class="c8">Registration and Your Information. </span><span class="c0">To purchase the Subscription
            Services, you shall create an Account with accurate,
            complete and up-to-date information. You shall update such information, as needed, to
            ensure the Account is accurate, complete and up-to-date. If you do not comply with your
            obligations in this Section, ASG may suspend or terminate your Account. You shall not
            disclose your Account password to anyone, and you shall notify us immediately of any
            unauthorized use of your Account. You are responsible for all activities that occur
            under your Account, whether or not you know about them. You shall not post or transmit
            any &ldquo;sensitive&rdquo; personally identifiable information over the Site, which may
            include, but not be limited to, any information about yourself or another person that
            may relate to health or medical conditions, social security numbers or national
            identifiers, credit card, bank account or other financial information, other information
            concerning trade union membership, sex life, political opinions, criminal charges or
            convictions, religious or philosophical beliefs, racial or ethnic origin, or other
            sensitive matters.</span>
        </li>
        <li class="c16 li-bullet-0">
          <span class="c8">Third-Party Sites, Services and/or Resources. </span><span class="c0">The Subscription
            Services may contain links to third-party websites, services and/or
            resources. We provide these links as a convenience and are not responsible for the
            content, products or services on or available from those websites or resources or links
            displayed on such sites. You acknowledge sole responsibility for and assume all risk
            arising from, your use of any third-party websites, services and/or resources and
            acknowledge such use is subject to any terms of use of those websites, services and/or
            resources.</span>
        </li>
        <li class="c16 li-bullet-0" id="h.44sinio"><span class="c8">Fees and Payment.</span></li>
      </ol>
      <ol class="c4 lst-kix_list_19-1 start" start="1">
        <li class="c5 li-bullet-0" id="h.2jxsxqh">
          <span class="c8">Subscription Fees. </span><span class="c0">ASG&rsquo;s Subscription Services are provided in
            exchange for the annual fee (the
            &ldquo;</span><span class="c8">Subscription Fee</span><span class="c0">&rdquo;) as listed in the table
            below. You shall pay all applicable Subscription Fees
            and all taxes and other fees that may accrue in relation to your use of a Subscription
            Plan, if any. Subscription Fees will be payable via credit card. You hereby consent to
            your credit card being charged for the applicable Subscription Fees for the Subscription
            Services in accordance with Section 8. All Subscription Fees are non-refundable,
            non-transferable, and are payable in United States dollars without offset or deduction
            according to the Subscription Plan selected. If you terminate this Agreement before the
            end of the Initial Term or a Renewal Term, then the full amount of the training term
            will be billed.
          </span>
        </li>
      </ol>
      <a id="t.4290083c73409e7b3d35f42448be2d7292586f0e"></a><a id="t.0"></a>
      <table class="c12">
        <tr class="c6">
          <td class="c15" colspan="3" rowspan="1">
            <p class="c10"><span class="c1">Subscription Plans</span></p>
          </td>
        </tr>
        <tr class="c6">
          <td class="c18 c19" colspan="1" rowspan="1">
            <p class="c17"><span class="c1">Subscription Plan</span></p>
          </td>
          <td class="c11 c18" colspan="1" rowspan="1">
            <p class="c17"><span class="c1">Description</span></p>
          </td>
          <td class="c14 c18" colspan="1" rowspan="1">
            <p class="c17"><span class="c1">Subscription Fees &amp; Monthly License Fee</span></p>
          </td>
        </tr>
        <tr class="c6">
          <td class="c19" colspan="1" rowspan="1">
            <p class="c17"><span class="c24">Individual Learner</span></p>
          </td>
          <td class="c11" colspan="1" rowspan="1">
            <p class="c17">
              <span class="c1">For the salespeople who want to level up and become a Gap Seller.</span>
            </p>
            <ul class="c4 lst-kix_list_14-0 start">
              <li class="c2 li-bullet-1"><span class="c0">1 Participant</span></li>
              <li class="c2 li-bullet-2"><span class="c0">6-8 Hours of sales training</span></li>
              <li class="c2 li-bullet-2"><span class="c0">Interactive learning</span></li>
              <li class="c2 li-bullet-2"><span class="c0">Your own unique journey</span></li>
            </ul>
          </td>
          <td class="c14" colspan="1" rowspan="1">
            <p class="c17">
              <span>An annual Subscription Fee of </span><span class="c24">$499</span><span class="c1">&nbsp;per year.
              </span>
            </p>
            <p class="c17"><span class="c1">Optional Additional Charges:</span></p>
            <ul class="c4 lst-kix_list_25-0 start">
              <li class="c9 li-bullet-1"><span class="c0">Gap+ Add On:</span></li>
            </ul>
            <ul class="c4 lst-kix_list_25-1 start">
              <li class="c3 li-bullet-2">
                <span class="c0">$35 multiplied by the number of months remaining in the current term.</span>
              </li>
              <li class="c3 li-bullet-1">
                <span class="c0">$948 for each subsequent Renewal Term.</span>
              </li>
              <li class="c3 li-bullet-1">
                <span class="c0">Access to Gap+ cannot be purchased separately.</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c6">
          <td class="c19" colspan="1" rowspan="1">
            <p class="c17"><span class="c1">Team Work</span></p>
          </td>
          <td class="c11" colspan="1" rowspan="1">
            <p class="c17">
              <span class="c1">For all the front-line sales managers who want a Gap Selling team. Team starter
                package includes:</span>
            </p>
            <ul class="c4 lst-kix_list_14-0">
              <li class="c2 li-bullet-2"><span class="c0">6 Training Users</span></li>
              <li class="c2 li-bullet-1"><span class="c0">2 Admin Users</span></li>
              <li class="c2 li-bullet-1"><span class="c0">6-8 Hours training</span></li>
              <li class="c2 li-bullet-1"><span class="c0">Interactive learning</span></li>
              <li class="c2 li-bullet-2"><span class="c0">Choose your own journey</span></li>
              <li class="c2 li-bullet-1"><span class="c0">Team reporting</span></li>
            </ul>
          </td>
          <td class="c14" colspan="1" rowspan="1">
            <p class="c17">
              <span>An annual Subscription Fee of </span><span class="c24">$2,599</span><span class="c1">.</span>
            </p>
            <p class="c17"><span class="c1">Optional Additional Charges: </span></p>
            <ul class="c4 lst-kix_list_24-0 start">
              <li class="c9 li-bullet-2">
                <span class="c0">$425 per Extra Training User per year</span>
              </li>
              <li class="c9 li-bullet-2">
                <span class="c0">$100 per Extra Admin per year</span>
              </li>
              <li class="c9 li-bullet-1"><span class="c0">Gap+ Add On:</span></li>
            </ul>
            <ul class="c4 lst-kix_list_24-1 start">
              <li class="c3 li-bullet-1">
                <span class="c0">$35 per total number of users, multiplied by the number of months remaining in
                  the current term.</span>
              </li>
              <li class="c3 li-bullet-2">
                <span class="c0">$79 per total number of users, multiplied by 12, for each subsequent Renewal
                  Term.</span>
              </li>
              <li class="c3 li-bullet-2">
                <span class="c0">Access to Gap+ cannot be purchased separately.</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c6">
          <td class="c19" colspan="1" rowspan="1">
            <p class="c17"><span class="c1">Small Company</span></p>
          </td>
          <td class="c11" colspan="1" rowspan="1">
            <p class="c17">
              <span class="c1">All the basics you need to transform your sales organization into a Gap Selling
                powerhouse. Small company starter package includes:</span>
            </p>
            <ul class="c4 lst-kix_list_15-0 start">
              <li class="c2 li-bullet-1"><span class="c0">12 Training Users</span></li>
              <li class="c2 li-bullet-2"><span class="c0">2 Admin Users</span></li>
              <li class="c2 li-bullet-1"><span class="c0">6-8 Hours training</span></li>
              <li class="c2 li-bullet-1"><span class="c0">Interactive learning</span></li>
              <li class="c2 li-bullet-1"><span class="c0">Choose your own journey</span></li>
              <li class="c2 li-bullet-1"><span class="c0">Organizational</span></li>
              <li class="c2 li-bullet-1"><span class="c0">Reporting</span></li>
              <li class="c2 li-bullet-2"><span class="c0">Team reporting</span></li>
            </ul>
          </td>
          <td class="c14" colspan="1" rowspan="1">
            <p class="c17">
              <span>An annual Subscription Fee of </span><span class="c24">$5,299</span><span class="c1">.</span>
            </p>
            <p class="c17"><span class="c1">Optional Additional Charges: </span></p>
            <ul class="c4 lst-kix_list_26-0 start">
              <li class="c9 li-bullet-1">
                <span class="c0">$399 per Extra Training User per year</span>
              </li>
              <li class="c9 li-bullet-1">
                <span class="c0">$100 per Extra Admin per year</span>
              </li>
              <li class="c9 li-bullet-1"><span class="c0">Gap+ Add On:</span></li>
            </ul>
            <ul class="c4 lst-kix_list_26-1 start">
              <li class="c3 li-bullet-1">
                <span class="c0">$35 per total number of users, multiplied by the number of months remaining in
                  the current term.</span>
              </li>
              <li class="c3 li-bullet-1">
                <span class="c0">$79 per total number of users, multiplied by 12, for each subsequent Renewal
                  Term.</span>
              </li>
              <li class="c3 li-bullet-1">
                <span class="c0">Access to Gap+ cannot be purchased separately.</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c6">
          <td class="c19" colspan="1" rowspan="1">
            <p class="c17"><span class="c1">Large Company</span></p>
          </td>
          <td class="c11" colspan="1" rowspan="1">
            <p class="c17">
              <span class="c1">For medium to large sales organizations that need training and robust reporting.
                Large company starter package includes:</span>
            </p>
            <ul class="c4 lst-kix_list_16-0 start">
              <li class="c2 li-bullet-1"><span class="c0">48 Training Users</span></li>
              <li class="c2 li-bullet-1"><span class="c0">3 Admin Users</span></li>
              <li class="c2 li-bullet-1"><span class="c0">6-8 Hours training</span></li>
              <li class="c2 li-bullet-2"><span class="c0">Interactive learning</span></li>
              <li class="c2 li-bullet-2"><span class="c0">Choose your own journey</span></li>
              <li class="c2 li-bullet-1"><span class="c0">Organizational Reporting</span></li>
              <li class="c2 li-bullet-1"><span class="c0">Regional Reporting</span></li>
              <li class="c2 li-bullet-1"><span class="c0">Team Reporting</span></li>
              <li class="c2 li-bullet-1"><span class="c0">Organizational Chart</span></li>
            </ul>
          </td>
          <td class="c14" colspan="1" rowspan="1">
            <p class="c17">
              <span>An annual Subscription Fee of </span><span class="c24">$14,999</span><span class="c1">. </span>
            </p>
            <p class="c17"><span class="c1">Optional Additional Charges:</span></p>
            <ul class="c4 lst-kix_list_27-0 start">
              <li class="c9 li-bullet-1">
                <span class="c0">$299 per Extra Training User per year</span>
              </li>
              <li class="c9 li-bullet-1">
                <span class="c0">$100 per Extra Admin per year</span>
              </li>
              <li class="c9 li-bullet-1"><span class="c0">Gap+ Add On:</span></li>
            </ul>
            <ul class="c4 lst-kix_list_27-1 start">
              <li class="c3 li-bullet-2">
                <span class="c0">$35 per total number of users, multiplied by the number of months remaining in
                  the current term.</span>
              </li>
              <li class="c3 li-bullet-1">
                <span class="c0">$79 per total number of users, multiplied by 12, for each subsequent Renewal
                  Term.</span>
              </li>
              <li class="c3 li-bullet-1">
                <span class="c0">Access to Gap+ cannot be purchased separately.</span>
              </li>
            </ul>
          </td>
        </tr>
      </table>
      <ol class="c4 lst-kix_list_19-1" start="2">
        <li class="c20 li-bullet-0" id="h.z337ya">
          <span class="c13">Late Payment.</span><span class="c0">&nbsp;If you fail to make any payment when due, without
            limiting ASG&rsquo;s other
            rights and remedies, ASG may charge interest on the past due amount at the rate of 1.5%
            per month calculated daily and compounded monthly or, if lower, the highest rate
            permitted under applicable law, or immediately terminate this Agreement, without
            incurring any obligation or liability to you or any other person by reason of such
            termination.</span>
        </li>
        <li class="c5 li-bullet-0" id="h.3j2qqm3">
          <span class="c13">Changes</span><span class="c0">. ASG may increase or otherwise modify the Subscription Fees
            at any time by providing
            at least thirty (30) days&rsquo; written notice to you, in which case, if you do not
            agree to such modifications, your sole and exclusive remedy is to terminate this
            Agreement. Failure to terminate this Agreement after such 30-day period shall be deemed
            your acceptance of such modification(s), and the Subscription Fees will be deemed
            amended accordingly.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-0" start="8">
        <li class="c16 c26 li-bullet-0" id="h.1y810tw">
          <span class="c8">Payment Processing.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-1 start" start="1">
        <li class="c5 li-bullet-0">
          <span class="c0">ASG processes payments (&ldquo;Payment Processing&rdquo;) which are provided by our
            third-party payment processing partners, including Stripe, Inc., and use of the Payment
            Processing by you will be subject to a separate merchant agreement which will be solely
            between you and the applicable third party processor. If you use Payment Processing you
            agree that you will comply with the terms and conditions of all applicable merchant
            agreements and all applicable card network rules, policies, laws and regulations, at all
            times while using such Payment Processing.</span>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c0">By entering into this Agreement, you authorize and expressly permit us to collect any
            fees or other amounts owed to us under this Agreement directly via Payment Processing.
            In addition to any amounts due, delinquent accounts may be charged with fees that are
            incidental to the collection of delinquent accounts and chargebacks including, but not
            limited to, collection fees and convenience fees and other third-party charges. You
            hereby explicitly agree that all communication in relation to delinquent accounts will
            be made by electronic mail or by phone, as provided to ASG by you. Such communication
            may be made by ASG or by anyone on its behalf, including but not limited to a
            third-party collection agent.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-0" start="9">
        <li class="c16 li-bullet-0">
          <span class="c8">Term, Termination, and Effect of Termination.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-1 start" start="1">
        <li class="c5 li-bullet-0" id="h.4i7ojhp">
          <span class="c0">The initial term of the Subscription Services under this Agreement begins on date you
            begin using the Subscription Service and, unless terminated earlier as provided herein,
            will continue in effect for twelve (12) months from such date (the &ldquo;</span><span class="c8">Initial
            Term</span><span class="c0">&rdquo;). The Subscription Services and associated Subscription Fees under this
            Agreement will automatically renew for additional (12) month Renewal Terms unless
            terminated earlier as provided herein (each a &ldquo;</span><span class="c8">Renewal Term</span><span
            class="c0">&rdquo;</span><a id="id.2xcytpi"></a><span class="c0">). &ldquo;</span><span
            class="c8">Subscription Term</span><span class="c0">&rdquo; means the Initial Term and all Renewal
            Terms.</span>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c0">You may request cancellation of your Account and termination of this Agreement at any
            time by sending an email to ASG at support@salesgrowth.com. </span><a id="id.1ci93xb"></a><span
            class="c0">However, as noted above, even in the event of early cancellation and termination of
            this Agreement, you shall remain responsible for paying the Subscription Fees remaining
            for the applicable Subscription Term.</span>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c0">ASG may terminate your access to and use of the Subscription Services, at our sole
            discretion, at any time and without notice to you.</span>
        </li>
        <li class="c5 li-bullet-0" id="h.3whwml4">
          <span class="c0">ASG may also terminate this Agreement: (i) with or without notice to you, if ASG
            terminates the Gap Selling Program; or (ii) effective on written notice if you
            materially breach this Agreement, including without limitation, if you fail to pay any
            amount when due hereunder, or you breach any of your obligations under Sections 2 or
            3</span><a id="id.2bn6wsx"></a><span class="c0">.</span>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c0">Upon any expiration, discontinuation, or termination of Subscription Services all
            rights and licenses granted to you pursuant to this Agreement, including in Section 2,
            shall terminate and you shall immediately cease use of all the Subscription Services. In
            respect of the Subscription Services, ASG shall cease to make the Subscription Services
            available to you.</span>
        </li>
      </ol>
      <a id="id.qsh70q"></a>
      <ol class="c4 lst-kix_list_19-1" start="6">
        <li class="c5 li-bullet-0" id="h.3as4poj">
          <span class="c0">Notwithstanding anything to the contrary herein, the rights and obligations set forth
            in the following sections shall survive any expiration or termination of this Agreement:
            Sections 2, 3, 7, 9-14.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-0" start="10">
        <li class="c16 li-bullet-0" id="h.1pxezwc"><span class="c8">Disclaimer. </span></li>
      </ol>
      <ol class="c4 lst-kix_list_19-1 start" start="1">
        <li class="c5 li-bullet-0">
          <span class="c0">THE SUBSCRIPTION SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE
            SUBSCRIPTION SERVICE ARE PROVIDED &ldquo;AS IS&rdquo; AND ON AN &ldquo;AS
            AVAILABLE&rdquo; BASIS. ASG DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED, RELATING TO THE SUBSCRIPTION SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE
            THROUGH THE SUBSCRIPTION SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR
            NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR
            TRADE. ASG DOES NOT WARRANT THAT THE SUBSCRIPTION SERVICE OR ANY PORTION OF THE
            SUBSCRIPTION SERVICE, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE SUBSCRIPTION
            SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL
            COMPONENTS, AND ASG DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED. ASG
            RESERVES THE RIGHT TO MODIFY AVAILABLE SUBSCRIPTION PLANS.</span>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c0">YOU ACKNOWLEDGE THAT THE SUBSCRIPTION SERVICES INCLUDE ACCESS TO AND USE OF PRODUCTS
            WHICH HAVE NOT BEEN DEVELOPED SPECIFICALLY FOR YOU. EXCEPT AS EXPRESSLY AND SPECIFICALLY
            PROVIDED IN THIS AGREEMENT, YOU ASSUME SOLE RESPONSIBILITY FOR RESULTS OBTAINED FROM USE
            OF THE SUBSCRIPTION SERVICES, AND FOR CONCLUSIONS DRAWN FROM SUCH USE. ASG SHALL HAVE NO
            LIABILITY FOR ANY DAMAGE OR LOSS CAUSED BY ERRORS OR OMISSIONS IN ANY INFORMATION
            PROVIDED TO ASG BY YOU IN CONNECTION WITH THE SUBSCRIPTION SERVICES.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-0" start="11">
        <li class="c16 li-bullet-0" id="h.49x2ik5">
          <span class="c8">Limitation of Liability. </span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-1 start" start="1">
        <li class="c5 li-bullet-0" id="h.2p2csry">
          <span class="c0">IN NO EVENT SHALL ASG HAVE ANY LIABILITY TO YOU OR TO ANY THIRD PARTY FOR ANY LOSS OF
            USE, LOST DATA, INTERRUPTION OF BUSINESS, LOST PROFITS OR REVENUES OR FOR ANY INDIRECT,
            SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN
            CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT ANY PARTY HAS
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING DISCLAIMER SHALL NOT
            APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW. IN NO EVENT SHALL ASG&rsquo;S
            AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT (WHETHER IN CONTRACT OR
            TORT OR UNDER ANY OTHER THEORY OF LIABILITY) EXCEED THE TOTAL SUBSCRIPTION FEES PAID BY
            YOU FOR THE SUBSCRIPTION SERVICES IN THE TWELVE (12) MONTHS BEFORE THE CLAIM
            AROSE.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-0" start="12">
        <li class="c16 li-bullet-0" id="h.147n2zr"><span class="c8">Indemnification.</span></li>
      </ol>
      <ol class="c4 lst-kix_list_19-1 start" start="1">
        <li class="c5 li-bullet-0" id="h.3o7alnk">
          <span class="c0">To the fullest extent permitted by law, you are responsible for your use of the
            Subscription Services under this Agreement. You shall indemnify, defend (at ASG&rsquo;s
            option), and hold ASG, its affiliates, and its and their respective officers, directors,
            employees and contractors harmless from and against any and all third-party claims,
            losses, liabilities, damages, costs and expenses, including reasonable attorney&rsquo;s
            fees, arising out of, relating to, or caused by: (a) your unauthorized use of, or misuse
            of, the Subscription Service; (b) your violation of any portion of this Agreement, any
            representation, warranty, or agreement referenced in this Agreement, or any applicable
            law or regulation; (c) your violation of any third-party right, including any
            intellectual property right or publicity, confidentiality, other property, or privacy
            right; or (d) any dispute or issue between you and any third-party.</span>
        </li>
        <li class="c5 li-bullet-0" id="h.23ckvvd">
          <span class="c0">ASG shall notify you in writing upon becoming aware of any third-party claim under
            Section 11.a. Upon ASG&rsquo;s written request, you shall promptly assume control of the
            defense and investigation of such third-party claim, with counsel reasonably acceptable
            to ASG, and the ASG shall reasonably cooperate with you in connection therewith, in each
            case, at your sole cost and expense. ASG may participate in the defense of such
            third-party claim, with counsel of its own choosing and at its own cost and expense. You
            shall not settle any such third-party claim without ASG&rsquo;s prior written consent
            (which consent shall not be unreasonably withheld or delayed). ASG reserves the right,
            at its own expense, to assume the exclusive defense and control of any matter otherwise
            subject to indemnification by you (without limiting your indemnification obligations
            with respect to that matter), and in that case, you agree to cooperate with our defense
            of those claims.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-0" start="13">
        <li class="c16 li-bullet-0" id="h.ihv636">
          <span class="c8">Changes to ASG IP. </span><span class="c0">ASG may modify the Subscription Services and/or
            any other ASG IP, from time-to-time, in
            its sole discretion, in which case, if you do not agree to such modifications, your sole
            and exclusive remedy is to terminate this Agreement.</span>
        </li>
        <li class="c16 li-bullet-0" id="h.32hioqz">
          <span class="c8">Feedback. </span><span class="c0">If you provide any feedback, ideas, or suggestions
            regarding ASG, the Subscription
            Services, or any other ASG IP (&ldquo;</span><span class="c8">Feedback</span><span class="c0">&rdquo;), such
            Feedback will be the exclusive property of ASG, and ASG may use it for
            any purpose. To the extent you own any rights in such Feedback, you shall assign and
            hereby do assign to ASG all right, title and interest in and to the Feedback including
            all associated intellectual property rights, and you acknowledge and agree that ASG is
            under no obligation to compensate or credit you for such assignment. You agree to
            perform all acts reasonably requested by ASG to perfect and enforce such rights.</span>
        </li>
        <li class="c16 li-bullet-0" id="h.1hmsyys"><span class="c8">General.</span></li>
      </ol>
      <ol class="c4 lst-kix_list_19-1 start" start="1">
        <li class="c5 li-bullet-0" id="h.41mghml">
          <span class="c13">Relationship of the Parties</span><span class="c0">. Nothing in this Agreement creates any
            agency, joint venture, partnership, or other
            form of joint enterprise, employment, or fiduciary relationship between you or ASG.
            Neither party has any express or implied right or authority to assume or create any
            obligations on behalf of or in the name of the other party or to bind the other party to
            any contract, agreement, or undertaking with any customer or other third-party.
          </span>
        </li>
        <li class="c5 li-bullet-0" id="h.2grqrue">
          <span class="c13">Equitable Relief</span><span class="c0">. You acknowledge that a breach by you of this
            Agreement will cause ASG irreparable
            damages, for which an award of damages would not be adequate compensation, and that, in
            the event of such breach or threatened breach, ASG will be entitled to equitable relief
            without having to post bond or prove monetary damages, including a restraining order,
            injunctive relief, specific performance, and any other relief that may be available from
            any court, in addition to any other remedy to which ASG may be entitled at law or in
            equity. Such remedies are not exclusive but are in addition to all other remedies
            available at law or in equity, subject to any express exclusions or limitations in this
            Agreement to the contrary.</span><a id="id.vx1227"></a>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c13">Consent to Electronic Communication</span><span class="c0">. By creating an Account, you
            consent to receiving certain electronic communications
            from ASG. Please read the Privacy Policy to learn more about our electronic
            communications practices. You agree that any notices, agreements, disclosures, or other
            communications that we send to you electronically will satisfy any legal communication
            requirements, including that those communications be in writing. For notices made by
            email, the date of receipt will be deemed the date on which such notice is transmitted.
            ASG may send you emails concerning our products and services, as well as those of third
            parties. You may opt out of promotional emails by following the unsubscribe instructions
            in the promotional email itself.
          </span>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c13">Entire Agreement</span><span class="c0">. This Agreement constitutes the sole and entire
            agreement of the parties with respect
            to the subject matter contained herein, and supersedes all prior and contemporaneous
            understandings, agreements, representations, and warranties, both written and oral, with
            respect to such subject matter. </span><a id="id.3fwokq0"></a>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c13">Severability</span><span class="c0">. If any term or provision of this Agreement is invalid,
            illegal, or unenforceable in
            any jurisdiction, such invalidity, illegality, or unenforceability will not affect the
            enforceability of any other term or provision of this Agreement, or invalidate or render
            unenforceable such term or provision in any other jurisdiction.</span><a id="id.1v1yuxt"></a>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c13">Interpretation.</span><span class="c0">&nbsp;In this Agreement:</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-2 start" start="1">
        <li class="c21 li-bullet-0">
          <span class="c0">the headings are for convenience only and do not affect its interpretation;</span>
        </li>
        <li class="c21 li-bullet-0">
          <span class="c0">words importing any gender include every gender, references to the singular include the
            plural and vice versa;</span>
        </li>
        <li class="c21 li-bullet-0">
          <span class="c0">references to any statute or any section of any statute include any statutory
            amendment, modification or re-enactment and instruments and regulations under it in
            force from time to time;
          </span>
        </li>
        <li class="c21 li-bullet-0">
          <span class="c0">the words and phrases &ldquo;other&rdquo;, &ldquo;including&rdquo; and &ldquo;in
            particular&rdquo; do not limit the meaning of any preceding words and words which follow
            them shall not be interpreted as being limited in scope to the same class as the
            preceding words where a wider interpretation is possible.</span>
        </li>
      </ol>
      <ol class="c4 lst-kix_list_19-1" start="7">
        <li class="c5 li-bullet-0" id="h.2u6wntf">
          <span class="c13">Assignment</span><span class="c0">. You shall not assign any of your rights or delegate any
            of your obligations under
            this Agreement without the prior written consent of ASG. Any purported assignment or
            delegation in violation of this Section is null and void. ASG may freely assign or
            otherwise transfer any of its rights or delegate any of its obligations under this
            Agreement. This Agreement is binding upon and inures to the benefit of the parties
            hereto and their respective permitted successors and assigns.</span><a id="id.4f1mdlm"></a>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c13">Choice of Law; Venue</span><span class="c0">. This Agreement and all matters arising out of
            this Agreement are governed by the laws
            of Colorado, without giving effect to any conflict of laws provisions thereof. Either
            party shall institute any legal suit, action, or proceeding arising out of this
            Agreement in the federal or state courts in each case located in Denver, CO, and each
            party irrevocably submits to the exclusive jurisdiction of such courts in any legal
            suit, action, or proceeding</span><a id="id.19c6y18"></a><span class="c0">. </span>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c13">Waiver of Jury Trial</span><span class="c0">. Each party acknowledges and agrees that any
            controversy that may arise under this
            Agreement is likely to involve complicated and difficult issues and, therefore, each
            party irrevocably and unconditionally waives any right it may have to a trial by jury in
            respect of any legal action arising out of or relating to this Agreement, or the
            transactions contemplated hereby.</span><a id="id.3tbugp1"></a>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c13">No Third-Party Beneficiaries</span><span class="c0">. This Agreement is for the sole benefit
            of the parties hereto and their respective
            successors and permitted assigns, and nothing herein, express or implied, is intended to
            or will confer upon any third-party any legal or equitable right, benefit, or remedy of
            any nature whatsoever, under or by reason of this Agreement.</span><a id="id.28h4qwu"></a>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c13">Amendment and Modification</span><span class="c0">. No modification or amendment to this
            Agreement is effective unless it is in writing
            and signed by an authorized representative of each party.</span><a id="id.nmf14n"></a>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c13">Waiver</span><span class="c0">. No waiver by any party of any of the provisions hereof will
            be effective unless
            explicitly set forth in writing and signed by the party so waiving. Except as otherwise
            set forth in this Agreement, no failure to exercise, or delay in exercising, any right,
            remedy, power, or privilege arising from this Agreement will operate or be construed as
            a waiver thereof; nor will any single or partial exercise of any right, remedy, power,
            or privilege hereunder preclude any other or further exercise thereof, or the exercise
            of any other right, remedy, power, or privilege.</span>
        </li>
        <li class="c5 li-bullet-0">
          <span class="c13">Publicity</span><span class="c0">. ASG may list you on ASG&rsquo;s website to indicate your
            relationship with ASG as a
            current customer and refer to your status as a current customer to prospective customers
            of ASG.
          </span>
        </li>
      </ol>
      <div>
        <p class="c10 c28"><span class="c0"></span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style type="text/css">
@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);

ol.lst-kix_list_7-0 {
  list-style-type: none;
}

.lst-kix_list_14-1>li:before {
  content: "o  ";
}

.lst-kix_list_14-3>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_2-1>li {
  counter-increment: lst-ctn-kix_list_2-1;
}

ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 0;
}

.lst-kix_list_14-0>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_14-4>li:before {
  content: "o  ";
}

ol.lst-kix_list_18-5.start {
  counter-reset: lst-ctn-kix_list_18-5 0;
}

.lst-kix_list_6-1>li {
  counter-increment: lst-ctn-kix_list_6-1;
}

.lst-kix_list_14-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_14-7>li:before {
  content: "o  ";
}

ol.lst-kix_list_13-4.start {
  counter-reset: lst-ctn-kix_list_13-4 0;
}

.lst-kix_list_14-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_13-0>li {
  counter-increment: lst-ctn-kix_list_13-0;
}

ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}

.lst-kix_list_17-0>li {
  counter-increment: lst-ctn-kix_list_17-0;
}

.lst-kix_list_5-0>li {
  counter-increment: lst-ctn-kix_list_5-0;
}

.lst-kix_list_9-0>li {
  counter-increment: lst-ctn-kix_list_9-0;
}

ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}

ol.lst-kix_list_7-5 {
  list-style-type: none;
}

ol.lst-kix_list_7-6 {
  list-style-type: none;
}

ol.lst-kix_list_7-7 {
  list-style-type: none;
}

ol.lst-kix_list_7-8 {
  list-style-type: none;
}

ol.lst-kix_list_7-1 {
  list-style-type: none;
}

ol.lst-kix_list_7-2 {
  list-style-type: none;
}

ol.lst-kix_list_11-8.start {
  counter-reset: lst-ctn-kix_list_11-8 0;
}

ol.lst-kix_list_7-3 {
  list-style-type: none;
}

ol.lst-kix_list_7-4 {
  list-style-type: none;
}

.lst-kix_list_14-2>li:before {
  content: "\0025aa   ";
}

ol.lst-kix_list_5-3.start {
  counter-reset: lst-ctn-kix_list_5-3 0;
}

ol.lst-kix_list_12-0.start {
  counter-reset: lst-ctn-kix_list_12-0 0;
}

.lst-kix_list_4-3>li {
  counter-increment: lst-ctn-kix_list_4-3;
}

ol.lst-kix_list_17-1.start {
  counter-reset: lst-ctn-kix_list_17-1 0;
}

.lst-kix_list_14-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_18-8>li {
  counter-increment: lst-ctn-kix_list_18-8;
}

ol.lst-kix_list_8-8.start {
  counter-reset: lst-ctn-kix_list_8-8 0;
}

ol.lst-kix_list_10-4.start {
  counter-reset: lst-ctn-kix_list_10-4 0;
}

.lst-kix_list_7-2>li {
  counter-increment: lst-ctn-kix_list_7-2;
}

.lst-kix_list_5-0>li:before {
  content: "ARTICLE " counter(lst-ctn-kix_list_5-0, upper-roman) " ";
}

ol.lst-kix_list_6-0 {
  list-style-type: none;
}

ol.lst-kix_list_6-1 {
  list-style-type: none;
}

.lst-kix_list_5-4>li {
  counter-increment: lst-ctn-kix_list_5-4;
}

.lst-kix_list_24-7>li:before {
  content: "o  ";
}

.lst-kix_list_1-4>li {
  counter-increment: lst-ctn-kix_list_1-4;
}

ul.lst-kix_list_27-0 {
  list-style-type: none;
}

ul.lst-kix_list_27-1 {
  list-style-type: none;
}

.lst-kix_list_24-8>li:before {
  content: "\0025aa   ";
}

ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}

ul.lst-kix_list_27-2 {
  list-style-type: none;
}

.lst-kix_list_5-3>li:before {
  content: "(" counter(lst-ctn-kix_list_5-3, lower-roman) ") ";
}

ol.lst-kix_list_9-5.start {
  counter-reset: lst-ctn-kix_list_9-5 0;
}

.lst-kix_list_5-2>li:before {
  content: "(" counter(lst-ctn-kix_list_5-2, lower-latin) ") ";
}

.lst-kix_list_8-3>li {
  counter-increment: lst-ctn-kix_list_8-3;
}

.lst-kix_list_5-1>li:before {
  content: "Section " counter(lst-ctn-kix_list_5-0, upper-roman) "."
    counter(lst-ctn-kix_list_5-1, decimal-leading-zero) " ";
}

ol.lst-kix_list_18-0.start {
  counter-reset: lst-ctn-kix_list_18-0 0;
}

.lst-kix_list_24-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_5-7>li:before {
  content: " ";
}

.lst-kix_list_5-6>li:before {
  content: " ";
}

.lst-kix_list_5-8>li:before {
  content: " ";
}

.lst-kix_list_24-3>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_24-4>li:before {
  content: "o  ";
}

.lst-kix_list_9-4>li {
  counter-increment: lst-ctn-kix_list_9-4;
}

ol.lst-kix_list_6-6 {
  list-style-type: none;
}

ul.lst-kix_list_27-3 {
  list-style-type: none;
}

.lst-kix_list_24-5>li:before {
  content: "\0025aa   ";
}

ol.lst-kix_list_6-7 {
  list-style-type: none;
}

ul.lst-kix_list_27-4 {
  list-style-type: none;
}

.lst-kix_list_5-4>li:before {
  content: "(" counter(lst-ctn-kix_list_5-4, upper-latin) ") ";
}

ol.lst-kix_list_6-8 {
  list-style-type: none;
}

ul.lst-kix_list_27-5 {
  list-style-type: none;
}

ul.lst-kix_list_27-6 {
  list-style-type: none;
}

.lst-kix_list_5-5>li:before {
  content: " ";
}

ol.lst-kix_list_6-2 {
  list-style-type: none;
}

ul.lst-kix_list_27-7 {
  list-style-type: none;
}

ol.lst-kix_list_6-3 {
  list-style-type: none;
}

ul.lst-kix_list_27-8 {
  list-style-type: none;
}

ol.lst-kix_list_6-4 {
  list-style-type: none;
}

.lst-kix_list_24-6>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_6-5 {
  list-style-type: none;
}

.lst-kix_list_23-6>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_12-5.start {
  counter-reset: lst-ctn-kix_list_12-5 0;
}

.lst-kix_list_6-1>li:before {
  content: "(" counter(lst-ctn-kix_list_6-1, lower-latin) ") ";
}

.lst-kix_list_6-3>li:before {
  content: "(" counter(lst-ctn-kix_list_6-3, decimal) ") ";
}

.lst-kix_list_6-5>li {
  counter-increment: lst-ctn-kix_list_6-5;
}

.lst-kix_list_6-8>li {
  counter-increment: lst-ctn-kix_list_6-8;
}

.lst-kix_list_23-3>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_23-7>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_6-0>li:before {
  content: "(" counter(lst-ctn-kix_list_6-0, decimal) ") ";
}

.lst-kix_list_6-4>li:before {
  content: "(" counter(lst-ctn-kix_list_6-4, lower-latin) ") ";
}

.lst-kix_list_23-2>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_16-2 {
  list-style-type: none;
}

ul.lst-kix_list_16-1 {
  list-style-type: none;
}

ul.lst-kix_list_16-0 {
  list-style-type: none;
}

.lst-kix_list_23-0>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_23-8>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_23-1>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_6-2>li:before {
  content: "" counter(lst-ctn-kix_list_6-2, lower-roman) ") ";
}

.lst-kix_list_24-1>li:before {
  content: "o  ";
}

ul.lst-kix_list_16-8 {
  list-style-type: none;
}

ul.lst-kix_list_16-7 {
  list-style-type: none;
}

ul.lst-kix_list_16-6 {
  list-style-type: none;
}

.lst-kix_list_2-5>li {
  counter-increment: lst-ctn-kix_list_2-5;
}

.lst-kix_list_2-8>li {
  counter-increment: lst-ctn-kix_list_2-8;
}

ul.lst-kix_list_16-5 {
  list-style-type: none;
}

.lst-kix_list_24-0>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_16-4 {
  list-style-type: none;
}

.lst-kix_list_6-8>li:before {
  content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ". ";
}

ul.lst-kix_list_16-3 {
  list-style-type: none;
}

.lst-kix_list_6-5>li:before {
  content: "(" counter(lst-ctn-kix_list_6-5, lower-roman) ") ";
}

.lst-kix_list_6-7>li:before {
  content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ". ";
}

.lst-kix_list_23-4>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_23-5>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_6-6>li:before {
  content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
}

ol.lst-kix_list_17-8 {
  list-style-type: none;
}

ol.lst-kix_list_10-6.start {
  counter-reset: lst-ctn-kix_list_10-6 0;
}

.lst-kix_list_7-4>li:before {
  content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". ";
}

.lst-kix_list_7-6>li:before {
  content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
}

ol.lst-kix_list_17-4 {
  list-style-type: none;
}

.lst-kix_list_18-5>li {
  counter-increment: lst-ctn-kix_list_18-5;
}

ol.lst-kix_list_19-7.start {
  counter-reset: lst-ctn-kix_list_19-7 0;
}

ol.lst-kix_list_17-5 {
  list-style-type: none;
}

ol.lst-kix_list_6-2.start {
  counter-reset: lst-ctn-kix_list_6-2 0;
}

ol.lst-kix_list_17-6 {
  list-style-type: none;
}

.lst-kix_list_22-2>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_22-6>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_17-7 {
  list-style-type: none;
}

ol.lst-kix_list_17-0 {
  list-style-type: none;
}

ol.lst-kix_list_17-1 {
  list-style-type: none;
}

ol.lst-kix_list_17-2 {
  list-style-type: none;
}

.lst-kix_list_7-2>li:before {
  content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". ";
}

ol.lst-kix_list_17-3 {
  list-style-type: none;
}

.lst-kix_list_7-6>li {
  counter-increment: lst-ctn-kix_list_7-6;
}

.lst-kix_list_8-6>li {
  counter-increment: lst-ctn-kix_list_8-6;
}

.lst-kix_list_22-0>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_22-8>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_12-6>li {
  counter-increment: lst-ctn-kix_list_12-6;
}

ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}

ol.lst-kix_list_9-7 {
  list-style-type: none;
}

ol.lst-kix_list_9-8 {
  list-style-type: none;
}

.lst-kix_list_13-7>li:before {
  content: "" counter(lst-ctn-kix_list_13-7, lower-latin) ") ";
}

ol.lst-kix_list_9-3 {
  list-style-type: none;
}

ol.lst-kix_list_9-4 {
  list-style-type: none;
}

.lst-kix_list_5-7>li {
  counter-increment: lst-ctn-kix_list_5-7;
}

ol.lst-kix_list_9-5 {
  list-style-type: none;
}

.lst-kix_list_7-8>li:before {
  content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". ";
}

ol.lst-kix_list_9-6 {
  list-style-type: none;
}

.lst-kix_list_4-7>li {
  counter-increment: lst-ctn-kix_list_4-7;
}

ol.lst-kix_list_9-0 {
  list-style-type: none;
}

ol.lst-kix_list_9-1 {
  list-style-type: none;
}

ol.lst-kix_list_9-2 {
  list-style-type: none;
}

.lst-kix_list_22-4>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}

.lst-kix_list_15-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_9-8>li {
  counter-increment: lst-ctn-kix_list_9-8;
}

.lst-kix_list_13-4>li {
  counter-increment: lst-ctn-kix_list_13-4;
}

.lst-kix_list_4-1>li:before {
  content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-1, decimal) " ";
}

.lst-kix_list_15-7>li:before {
  content: "o  ";
}

.lst-kix_list_17-7>li {
  counter-increment: lst-ctn-kix_list_17-7;
}

.lst-kix_list_4-3>li:before {
  content: "(" counter(lst-ctn-kix_list_4-3, lower-roman) ") ";
}

.lst-kix_list_4-5>li:before {
  content: " ";
}

.lst-kix_list_1-8>li {
  counter-increment: lst-ctn-kix_list_1-8;
}

.lst-kix_list_25-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_10-5>li {
  counter-increment: lst-ctn-kix_list_10-5;
}

.lst-kix_list_15-1>li:before {
  content: "o  ";
}

ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}

.lst-kix_list_25-7>li:before {
  content: "o  ";
}

ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}

ol.lst-kix_list_18-3.start {
  counter-reset: lst-ctn-kix_list_18-3 0;
}

.lst-kix_list_15-3>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_18-7 {
  list-style-type: none;
}

ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}

ol.lst-kix_list_18-8 {
  list-style-type: none;
}

ol.lst-kix_list_18-3 {
  list-style-type: none;
}

ol.lst-kix_list_18-4 {
  list-style-type: none;
}

.lst-kix_list_6-4>li {
  counter-increment: lst-ctn-kix_list_6-4;
}

ol.lst-kix_list_18-5 {
  list-style-type: none;
}

ol.lst-kix_list_18-6 {
  list-style-type: none;
}

ol.lst-kix_list_9-2.start {
  counter-reset: lst-ctn-kix_list_9-2 0;
}

ol.lst-kix_list_18-0 {
  list-style-type: none;
}

ol.lst-kix_list_18-1 {
  list-style-type: none;
}

.lst-kix_list_9-3>li {
  counter-increment: lst-ctn-kix_list_9-3;
}

ol.lst-kix_list_18-2 {
  list-style-type: none;
}

.lst-kix_list_11-2>li {
  counter-increment: lst-ctn-kix_list_11-2;
}

.lst-kix_list_19-2>li {
  counter-increment: lst-ctn-kix_list_19-2;
}

ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}

ol.lst-kix_list_8-8 {
  list-style-type: none;
}

.lst-kix_list_12-3>li:before {
  content: "(" counter(lst-ctn-kix_list_12-3, decimal) ") ";
}

ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}

ol.lst-kix_list_8-4 {
  list-style-type: none;
}

.lst-kix_list_12-1>li:before {
  content: "" counter(lst-ctn-kix_list_12-1, lower-latin) ". ";
}

ol.lst-kix_list_8-5 {
  list-style-type: none;
}

ol.lst-kix_list_8-6 {
  list-style-type: none;
}

ol.lst-kix_list_8-7 {
  list-style-type: none;
}

ol.lst-kix_list_8-0 {
  list-style-type: none;
}

ol.lst-kix_list_8-1 {
  list-style-type: none;
}

ol.lst-kix_list_8-2 {
  list-style-type: none;
}

.lst-kix_list_13-3>li {
  counter-increment: lst-ctn-kix_list_13-3;
}

ol.lst-kix_list_13-6.start {
  counter-reset: lst-ctn-kix_list_13-6 0;
}

ol.lst-kix_list_8-3 {
  list-style-type: none;
}

.lst-kix_list_10-4>li {
  counter-increment: lst-ctn-kix_list_10-4;
}

.lst-kix_list_13-3>li:before {
  content: "(" counter(lst-ctn-kix_list_13-3, decimal) ") ";
}

ol.lst-kix_list_5-8.start {
  counter-reset: lst-ctn-kix_list_5-8 0;
}

.lst-kix_list_1-3>li {
  counter-increment: lst-ctn-kix_list_1-3;
}

.lst-kix_list_13-5>li:before {
  content: "" counter(lst-ctn-kix_list_13-5, lower-roman) ". ";
}

.lst-kix_list_12-5>li:before {
  content: "" counter(lst-ctn-kix_list_12-5, lower-roman) ". ";
}

ol.lst-kix_list_13-7.start {
  counter-reset: lst-ctn-kix_list_13-7 0;
}

.lst-kix_list_18-4>li {
  counter-increment: lst-ctn-kix_list_18-4;
}

ol.lst-kix_list_12-2.start {
  counter-reset: lst-ctn-kix_list_12-2 0;
}

.lst-kix_list_12-7>li:before {
  content: "" counter(lst-ctn-kix_list_12-7, lower-latin) ") ";
}

ol.lst-kix_list_6-0.start {
  counter-reset: lst-ctn-kix_list_6-0 0;
}

.lst-kix_list_4-2>li {
  counter-increment: lst-ctn-kix_list_4-2;
}

.lst-kix_list_13-1>li:before {
  content: "" counter(lst-ctn-kix_list_13-1, lower-latin) ". ";
}

ul.lst-kix_list_24-0 {
  list-style-type: none;
}

.lst-kix_list_5-1>li {
  counter-increment: lst-ctn-kix_list_5-1;
}

ul.lst-kix_list_24-1 {
  list-style-type: none;
}

ol.lst-kix_list_19-0.start {
  counter-reset: lst-ctn-kix_list_19-0 0;
}

ul.lst-kix_list_24-2 {
  list-style-type: none;
}

ul.lst-kix_list_24-3 {
  list-style-type: none;
}

ul.lst-kix_list_24-4 {
  list-style-type: none;
}

ul.lst-kix_list_24-5 {
  list-style-type: none;
}

ol.lst-kix_list_11-6 {
  list-style-type: none;
}

ol.lst-kix_list_11-7 {
  list-style-type: none;
}

ol.lst-kix_list_11-8 {
  list-style-type: none;
}

.lst-kix_list_1-1>li {
  counter-increment: lst-ctn-kix_list_1-1;
}

.lst-kix_list_7-1>li {
  counter-increment: lst-ctn-kix_list_7-1;
}

ol.lst-kix_list_11-2 {
  list-style-type: none;
}

ol.lst-kix_list_11-3 {
  list-style-type: none;
}

ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}

.lst-kix_list_3-0>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_11-4 {
  list-style-type: none;
}

ol.lst-kix_list_11-5 {
  list-style-type: none;
}

ol.lst-kix_list_13-1.start {
  counter-reset: lst-ctn-kix_list_13-1 0;
}

ol.lst-kix_list_11-0 {
  list-style-type: none;
}

ol.lst-kix_list_11-1 {
  list-style-type: none;
}

.lst-kix_list_21-8>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_26-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_4-0>li {
  counter-increment: lst-ctn-kix_list_4-0;
}

.lst-kix_list_8-0>li {
  counter-increment: lst-ctn-kix_list_8-0;
}

ul.lst-kix_list_24-6 {
  list-style-type: none;
}

ul.lst-kix_list_24-7 {
  list-style-type: none;
}

.lst-kix_list_10-0>li {
  counter-increment: lst-ctn-kix_list_10-0;
}

.lst-kix_list_3-4>li:before {
  content: "o  ";
}

ul.lst-kix_list_24-8 {
  list-style-type: none;
}

ol.lst-kix_list_18-2.start {
  counter-reset: lst-ctn-kix_list_18-2 0;
}

.lst-kix_list_3-3>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_26-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_8-0>li:before {
  content: "" counter(lst-ctn-kix_list_8-0, decimal) ". ";
}

.lst-kix_list_8-7>li:before {
  content: " ";
}

.lst-kix_list_3-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_21-0>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_10-7.start {
  counter-reset: lst-ctn-kix_list_10-7 0;
}

.lst-kix_list_26-1>li:before {
  content: "o  ";
}

.lst-kix_list_13-1>li {
  counter-increment: lst-ctn-kix_list_13-1;
}

.lst-kix_list_21-1>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_8-3>li:before {
  content: "(" counter(lst-ctn-kix_list_8-3, upper-latin) ") ";
}

.lst-kix_list_3-7>li:before {
  content: "o  ";
}

.lst-kix_list_26-4>li:before {
  content: "o  ";
}

.lst-kix_list_8-4>li:before {
  content: " ";
}

.lst-kix_list_19-1>li {
  counter-increment: lst-ctn-kix_list_19-1;
}

.lst-kix_list_10-2>li {
  counter-increment: lst-ctn-kix_list_10-2;
}

ol.lst-kix_list_8-5.start {
  counter-reset: lst-ctn-kix_list_8-5 0;
}

.lst-kix_list_17-1>li {
  counter-increment: lst-ctn-kix_list_17-1;
}

.lst-kix_list_11-1>li:before {
  content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ". ";
}

.lst-kix_list_21-5>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_21-4>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_11-0>li:before {
  content: "" counter(lst-ctn-kix_list_11-0, decimal) ". ";
}

ol.lst-kix_list_9-3.start {
  counter-reset: lst-ctn-kix_list_9-3 0;
}

.lst-kix_list_26-0>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_8-8>li:before {
  content: " ";
}

ol.lst-kix_list_2-2 {
  list-style-type: none;
}

.lst-kix_list_16-8>li:before {
  content: "\0025aa   ";
}

ol.lst-kix_list_2-3 {
  list-style-type: none;
}

ul.lst-kix_list_23-0 {
  list-style-type: none;
}

ol.lst-kix_list_2-4 {
  list-style-type: none;
}

ul.lst-kix_list_23-1 {
  list-style-type: none;
}

.lst-kix_list_16-7>li:before {
  content: "o  ";
}

ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}

ol.lst-kix_list_2-5 {
  list-style-type: none;
}

ul.lst-kix_list_23-2 {
  list-style-type: none;
}

ul.lst-kix_list_23-3 {
  list-style-type: none;
}

.lst-kix_list_17-8>li {
  counter-increment: lst-ctn-kix_list_17-8;
}

ul.lst-kix_list_23-4 {
  list-style-type: none;
}

ol.lst-kix_list_2-0 {
  list-style-type: none;
}

ul.lst-kix_list_23-5 {
  list-style-type: none;
}

ol.lst-kix_list_2-1 {
  list-style-type: none;
}

ul.lst-kix_list_23-6 {
  list-style-type: none;
}

ol.lst-kix_list_19-5.start {
  counter-reset: lst-ctn-kix_list_19-5 0;
}

.lst-kix_list_4-8>li:before {
  content: " ";
}

ol.lst-kix_list_12-5 {
  list-style-type: none;
}

ol.lst-kix_list_12-6 {
  list-style-type: none;
}

.lst-kix_list_4-7>li:before {
  content: "" counter(lst-ctn-kix_list_4-7, decimal) " ";
}

ol.lst-kix_list_12-7 {
  list-style-type: none;
}

.lst-kix_list_17-0>li:before {
  content: "" counter(lst-ctn-kix_list_17-0, decimal) ". ";
}

ol.lst-kix_list_12-8 {
  list-style-type: none;
}

ol.lst-kix_list_12-1 {
  list-style-type: none;
}

ol.lst-kix_list_12-2 {
  list-style-type: none;
}

ol.lst-kix_list_12-3 {
  list-style-type: none;
}

ol.lst-kix_list_12-4 {
  list-style-type: none;
}

.lst-kix_list_16-0>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_12-0 {
  list-style-type: none;
}

ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}

.lst-kix_list_25-1>li:before {
  content: "o  ";
}

.lst-kix_list_8-4>li {
  counter-increment: lst-ctn-kix_list_8-4;
}

ul.lst-kix_list_23-7 {
  list-style-type: none;
}

.lst-kix_list_25-0>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_16-4>li:before {
  content: "o  ";
}

ul.lst-kix_list_23-8 {
  list-style-type: none;
}

ol.lst-kix_list_10-8.start {
  counter-reset: lst-ctn-kix_list_10-8 0;
}

.lst-kix_list_16-3>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_2-6 {
  list-style-type: none;
}

ol.lst-kix_list_2-7 {
  list-style-type: none;
}

ol.lst-kix_list_2-8 {
  list-style-type: none;
}

.lst-kix_list_11-3>li {
  counter-increment: lst-ctn-kix_list_11-3;
}

ol.lst-kix_list_18-1.start {
  counter-reset: lst-ctn-kix_list_18-1 0;
}

ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}

ol.lst-kix_list_8-6.start {
  counter-reset: lst-ctn-kix_list_8-6 0;
}

.lst-kix_list_17-7>li:before {
  content: "" counter(lst-ctn-kix_list_17-7, lower-latin) ". ";
}

.lst-kix_list_17-8>li:before {
  content: "" counter(lst-ctn-kix_list_17-8, lower-roman) ". ";
}

.lst-kix_list_17-3>li:before {
  content: "" counter(lst-ctn-kix_list_17-3, decimal) ". ";
}

.lst-kix_list_17-4>li:before {
  content: "" counter(lst-ctn-kix_list_17-4, lower-latin) ". ";
}

ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 0;
}

.lst-kix_list_7-0>li:before {
  content: "" counter(lst-ctn-kix_list_7-0, decimal) ".   ";
}

.lst-kix_list_13-8>li {
  counter-increment: lst-ctn-kix_list_13-8;
}

.lst-kix_list_2-2>li {
  counter-increment: lst-ctn-kix_list_2-2;
}

ol.lst-kix_list_19-6.start {
  counter-reset: lst-ctn-kix_list_19-6 0;
}

ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}

ol.lst-kix_list_5-0 {
  list-style-type: none;
}

ol.lst-kix_list_5-1 {
  list-style-type: none;
}

ol.lst-kix_list_9-7.start {
  counter-reset: lst-ctn-kix_list_9-7 0;
}

ol.lst-kix_list_5-2 {
  list-style-type: none;
}

.lst-kix_list_22-5>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_13-8 {
  list-style-type: none;
}

ul.lst-kix_list_26-0 {
  list-style-type: none;
}

.lst-kix_list_2-4>li:before {
  content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
}

.lst-kix_list_2-8>li:before {
  content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ") ";
}

ul.lst-kix_list_26-1 {
  list-style-type: none;
}

ul.lst-kix_list_26-2 {
  list-style-type: none;
}

ul.lst-kix_list_26-3 {
  list-style-type: none;
}

ol.lst-kix_list_13-4 {
  list-style-type: none;
}

ol.lst-kix_list_13-5 {
  list-style-type: none;
}

ol.lst-kix_list_13-6 {
  list-style-type: none;
}

ol.lst-kix_list_13-7 {
  list-style-type: none;
}

.lst-kix_list_22-1>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_13-0 {
  list-style-type: none;
}

ol.lst-kix_list_13-1 {
  list-style-type: none;
}

.lst-kix_list_27-4>li:before {
  content: "o  ";
}

.lst-kix_list_6-6>li {
  counter-increment: lst-ctn-kix_list_6-6;
}

ol.lst-kix_list_13-2 {
  list-style-type: none;
}

.lst-kix_list_7-3>li:before {
  content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
}

ol.lst-kix_list_13-3 {
  list-style-type: none;
}

.lst-kix_list_10-0>li:before {
  content: "" counter(lst-ctn-kix_list_10-0, decimal) ". ";
}

.lst-kix_list_9-7>li {
  counter-increment: lst-ctn-kix_list_9-7;
}

.lst-kix_list_13-6>li {
  counter-increment: lst-ctn-kix_list_13-6;
}

.lst-kix_list_13-8>li:before {
  content: "" counter(lst-ctn-kix_list_13-8, lower-roman) ") ";
}

.lst-kix_list_18-3>li:before {
  content: "" counter(lst-ctn-kix_list_18-3, decimal) ". ";
}

.lst-kix_list_18-7>li:before {
  content: "" counter(lst-ctn-kix_list_18-7, lower-latin) ". ";
}

ol.lst-kix_list_5-7 {
  list-style-type: none;
}

ul.lst-kix_list_26-4 {
  list-style-type: none;
}

ol.lst-kix_list_5-8 {
  list-style-type: none;
}

ul.lst-kix_list_26-5 {
  list-style-type: none;
}

ul.lst-kix_list_26-6 {
  list-style-type: none;
}

.lst-kix_list_18-6>li {
  counter-increment: lst-ctn-kix_list_18-6;
}

ul.lst-kix_list_26-7 {
  list-style-type: none;
}

ol.lst-kix_list_5-3 {
  list-style-type: none;
}

.lst-kix_list_8-7>li {
  counter-increment: lst-ctn-kix_list_8-7;
}

ul.lst-kix_list_26-8 {
  list-style-type: none;
}

.lst-kix_list_19-6>li {
  counter-increment: lst-ctn-kix_list_19-6;
}

ol.lst-kix_list_5-4 {
  list-style-type: none;
}

.lst-kix_list_27-0>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_5-5 {
  list-style-type: none;
}

ol.lst-kix_list_5-6 {
  list-style-type: none;
}

.lst-kix_list_7-7>li:before {
  content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". ";
}

ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0;
}

.lst-kix_list_15-4>li:before {
  content: "o  ";
}

.lst-kix_list_9-5>li {
  counter-increment: lst-ctn-kix_list_9-5;
}

ol.lst-kix_list_19-1.start {
  counter-reset: lst-ctn-kix_list_19-1 0;
}

.lst-kix_list_5-8>li {
  counter-increment: lst-ctn-kix_list_5-8;
}

.lst-kix_list_10-4>li:before {
  content: "" counter(lst-ctn-kix_list_10-4, lower-latin) ". ";
}

.lst-kix_list_10-8>li:before {
  content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ") ";
}

.lst-kix_list_4-0>li:before {
  content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
}

ul.lst-kix_list_15-3 {
  list-style-type: none;
}

ul.lst-kix_list_15-2 {
  list-style-type: none;
}

.lst-kix_list_15-0>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_15-1 {
  list-style-type: none;
}

.lst-kix_list_15-8>li:before {
  content: "\0025aa   ";
}

ul.lst-kix_list_15-0 {
  list-style-type: none;
}

ol.lst-kix_list_19-4.start {
  counter-reset: lst-ctn-kix_list_19-4 0;
}

.lst-kix_list_4-4>li:before {
  content: "(" counter(lst-ctn-kix_list_4-4, upper-latin) ") ";
}

ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}

.lst-kix_list_25-4>li:before {
  content: "o  ";
}

.lst-kix_list_19-4>li {
  counter-increment: lst-ctn-kix_list_19-4;
}

ul.lst-kix_list_15-8 {
  list-style-type: none;
}

ul.lst-kix_list_15-7 {
  list-style-type: none;
}

ul.lst-kix_list_15-6 {
  list-style-type: none;
}

.lst-kix_list_9-3>li:before {
  content: "(" counter(lst-ctn-kix_list_9-3, decimal) ") ";
}

ul.lst-kix_list_15-5 {
  list-style-type: none;
}

ul.lst-kix_list_15-4 {
  list-style-type: none;
}

ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 0;
}

.lst-kix_list_25-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_12-8>li {
  counter-increment: lst-ctn-kix_list_12-8;
}

ol.lst-kix_list_13-2.start {
  counter-reset: lst-ctn-kix_list_13-2 0;
}

ol.lst-kix_list_4-0 {
  list-style-type: none;
}

ol.lst-kix_list_4-1 {
  list-style-type: none;
}

ol.lst-kix_list_4-2 {
  list-style-type: none;
}

ol.lst-kix_list_4-3 {
  list-style-type: none;
}

ul.lst-kix_list_25-0 {
  list-style-type: none;
}

ul.lst-kix_list_25-1 {
  list-style-type: none;
}

ul.lst-kix_list_25-2 {
  list-style-type: none;
}

.lst-kix_list_9-7>li:before {
  content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ") ";
}

.lst-kix_list_2-4>li {
  counter-increment: lst-ctn-kix_list_2-4;
}

ul.lst-kix_list_25-3 {
  list-style-type: none;
}

ul.lst-kix_list_25-4 {
  list-style-type: none;
}

.lst-kix_list_11-4>li:before {
  content: "" counter(lst-ctn-kix_list_11-4, lower-latin) ". ";
}

.lst-kix_list_12-4>li:before {
  content: "" counter(lst-ctn-kix_list_12-4, lower-latin) ". ";
}

.lst-kix_list_5-3>li {
  counter-increment: lst-ctn-kix_list_5-3;
}

.lst-kix_list_20-5>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_4-8 {
  list-style-type: none;
}

.lst-kix_list_7-4>li {
  counter-increment: lst-ctn-kix_list_7-4;
}

ul.lst-kix_list_25-5 {
  list-style-type: none;
}

.lst-kix_list_1-0>li:before {
  content: "ARTICLE " counter(lst-ctn-kix_list_1-0, upper-roman) " ";
}

ul.lst-kix_list_25-6 {
  list-style-type: none;
}

ol.lst-kix_list_19-2.start {
  counter-reset: lst-ctn-kix_list_19-2 0;
}

.lst-kix_list_20-1>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_25-7 {
  list-style-type: none;
}

ul.lst-kix_list_25-8 {
  list-style-type: none;
}

ol.lst-kix_list_4-4 {
  list-style-type: none;
}

.lst-kix_list_11-8>li:before {
  content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ") ";
}

.lst-kix_list_12-3>li {
  counter-increment: lst-ctn-kix_list_12-3;
}

ol.lst-kix_list_4-5 {
  list-style-type: none;
}

ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}

ol.lst-kix_list_4-6 {
  list-style-type: none;
}

.lst-kix_list_12-0>li:before {
  content: "" counter(lst-ctn-kix_list_12-0, decimal) ". ";
}

.lst-kix_list_17-3>li {
  counter-increment: lst-ctn-kix_list_17-3;
}

ol.lst-kix_list_4-7 {
  list-style-type: none;
}

ol.lst-kix_list_8-4.start {
  counter-reset: lst-ctn-kix_list_8-4 0;
}

.lst-kix_list_1-4>li:before {
  content: "(" counter(lst-ctn-kix_list_1-4, upper-latin) ") ";
}

.lst-kix_list_13-0>li:before {
  content: "" counter(lst-ctn-kix_list_13-0, decimal) ". ";
}

ul.lst-kix_list_14-4 {
  list-style-type: none;
}

ul.lst-kix_list_14-3 {
  list-style-type: none;
}

.lst-kix_list_1-6>li {
  counter-increment: lst-ctn-kix_list_1-6;
}

ol.lst-kix_list_13-0.start {
  counter-reset: lst-ctn-kix_list_13-0 0;
}

ul.lst-kix_list_14-2 {
  list-style-type: none;
}

.lst-kix_list_13-4>li:before {
  content: "" counter(lst-ctn-kix_list_13-4, lower-latin) ". ";
}

ul.lst-kix_list_14-1 {
  list-style-type: none;
}

ul.lst-kix_list_14-0 {
  list-style-type: none;
}

.lst-kix_list_10-7>li {
  counter-increment: lst-ctn-kix_list_10-7;
}

ol.lst-kix_list_19-3.start {
  counter-reset: lst-ctn-kix_list_19-3 0;
}

ul.lst-kix_list_14-8 {
  list-style-type: none;
}

ul.lst-kix_list_14-7 {
  list-style-type: none;
}

.lst-kix_list_2-0>li:before {
  content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
}

ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}

.lst-kix_list_18-1>li {
  counter-increment: lst-ctn-kix_list_18-1;
}

ol.lst-kix_list_8-3.start {
  counter-reset: lst-ctn-kix_list_8-3 0;
}

ul.lst-kix_list_14-6 {
  list-style-type: none;
}

.lst-kix_list_11-5>li {
  counter-increment: lst-ctn-kix_list_11-5;
}

ul.lst-kix_list_14-5 {
  list-style-type: none;
}

.lst-kix_list_4-5>li {
  counter-increment: lst-ctn-kix_list_4-5;
}

ol.lst-kix_list_9-8.start {
  counter-reset: lst-ctn-kix_list_9-8 0;
}

.lst-kix_list_1-8>li:before {
  content: " ";
}

.lst-kix_list_27-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_12-8>li:before {
  content: "" counter(lst-ctn-kix_list_12-8, lower-roman) ") ";
}

.lst-kix_list_8-2>li {
  counter-increment: lst-ctn-kix_list_8-2;
}

.lst-kix_list_4-1>li {
  counter-increment: lst-ctn-kix_list_4-1;
}

ul.lst-kix_list_20-2 {
  list-style-type: none;
}

ul.lst-kix_list_20-3 {
  list-style-type: none;
}

.lst-kix_list_19-0>li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) ". ";
}

.lst-kix_list_19-1>li:before {
  content: "" counter(lst-ctn-kix_list_19-1, lower-latin) ". ";
}

ul.lst-kix_list_20-4 {
  list-style-type: none;
}

ul.lst-kix_list_20-5 {
  list-style-type: none;
}

ol.lst-kix_list_17-7.start {
  counter-reset: lst-ctn-kix_list_17-7 0;
}

ul.lst-kix_list_20-6 {
  list-style-type: none;
}

ul.lst-kix_list_20-7 {
  list-style-type: none;
}

ol.lst-kix_list_12-6.start {
  counter-reset: lst-ctn-kix_list_12-6 0;
}

ul.lst-kix_list_20-8 {
  list-style-type: none;
}

.lst-kix_list_19-4>li:before {
  content: "(" counter(lst-ctn-kix_list_19-4, upper-latin) ") ";
}

.lst-kix_list_8-1>li {
  counter-increment: lst-ctn-kix_list_8-1;
}

ol.lst-kix_list_8-2.start {
  counter-reset: lst-ctn-kix_list_8-2 0;
}

.lst-kix_list_19-2>li:before {
  content: "" counter(lst-ctn-kix_list_19-2, lower-roman) ". ";
}

.lst-kix_list_19-3>li:before {
  content: "(" counter(lst-ctn-kix_list_19-3, lower-roman) ") ";
}

ul.lst-kix_list_20-0 {
  list-style-type: none;
}

ul.lst-kix_list_20-1 {
  list-style-type: none;
}

ol.lst-kix_list_6-6.start {
  counter-reset: lst-ctn-kix_list_6-6 0;
}

.lst-kix_list_7-0>li {
  counter-increment: lst-ctn-kix_list_7-0;
}

.lst-kix_list_11-0>li {
  counter-increment: lst-ctn-kix_list_11-0;
}

.lst-kix_list_19-0>li {
  counter-increment: lst-ctn-kix_list_19-0;
}

ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}

ol.lst-kix_list_9-6.start {
  counter-reset: lst-ctn-kix_list_9-6 0;
}

.lst-kix_list_2-3>li {
  counter-increment: lst-ctn-kix_list_2-3;
}

ol.lst-kix_list_19-8.start {
  counter-reset: lst-ctn-kix_list_19-8 0;
}

ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}

.lst-kix_list_1-2>li {
  counter-increment: lst-ctn-kix_list_1-2;
}

.lst-kix_list_19-8>li:before {
  content: "" counter(lst-ctn-kix_list_19-8, decimal) ". ";
}

ol.lst-kix_list_11-2.start {
  counter-reset: lst-ctn-kix_list_11-2 0;
}

.lst-kix_list_5-2>li {
  counter-increment: lst-ctn-kix_list_5-2;
}

.lst-kix_list_19-5>li:before {
  content: "(" counter(lst-ctn-kix_list_19-5, decimal) ") ";
}

.lst-kix_list_19-6>li:before {
  content: "" counter(lst-ctn-kix_list_19-6, lower-latin) ". ";
}

.lst-kix_list_19-7>li:before {
  content: "" counter(lst-ctn-kix_list_19-7, lower-roman) ". ";
}

.lst-kix_list_9-2>li {
  counter-increment: lst-ctn-kix_list_9-2;
}

ol.lst-kix_list_8-7.start {
  counter-reset: lst-ctn-kix_list_8-7 0;
}

.lst-kix_list_17-2>li {
  counter-increment: lst-ctn-kix_list_17-2;
}

ol.lst-kix_list_17-2.start {
  counter-reset: lst-ctn-kix_list_17-2 0;
}

.lst-kix_list_13-2>li {
  counter-increment: lst-ctn-kix_list_13-2;
}

.lst-kix_list_19-7>li {
  counter-increment: lst-ctn-kix_list_19-7;
}

.lst-kix_list_18-3>li {
  counter-increment: lst-ctn-kix_list_18-3;
}

.lst-kix_list_10-3>li {
  counter-increment: lst-ctn-kix_list_10-3;
}

.lst-kix_list_12-1>li {
  counter-increment: lst-ctn-kix_list_12-1;
}

ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}

.lst-kix_list_18-0>li:before {
  content: "" counter(lst-ctn-kix_list_18-0, lower-latin) ". ";
}

ol.lst-kix_list_13-3.start {
  counter-reset: lst-ctn-kix_list_13-3 0;
}

.lst-kix_list_18-1>li:before {
  content: "" counter(lst-ctn-kix_list_18-1, lower-latin) ". ";
}

.lst-kix_list_18-2>li:before {
  content: "" counter(lst-ctn-kix_list_18-2, lower-roman) ". ";
}

ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}

li.li-bullet-2:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

ol.lst-kix_list_11-7.start {
  counter-reset: lst-ctn-kix_list_11-7 0;
}

ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}

ol.lst-kix_list_1-3 {
  list-style-type: none;
}

ul.lst-kix_list_22-0 {
  list-style-type: none;
}

ol.lst-kix_list_1-4 {
  list-style-type: none;
}

.lst-kix_list_2-7>li:before {
  content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ") ";
}

.lst-kix_list_2-7>li {
  counter-increment: lst-ctn-kix_list_2-7;
}

.lst-kix_list_27-1>li:before {
  content: "o  ";
}

ul.lst-kix_list_22-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-5 {
  list-style-type: none;
}

ul.lst-kix_list_22-2 {
  list-style-type: none;
}

ol.lst-kix_list_1-6 {
  list-style-type: none;
}

ul.lst-kix_list_22-3 {
  list-style-type: none;
}

ul.lst-kix_list_22-4 {
  list-style-type: none;
}

ol.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_2-5>li:before {
  content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
}

.lst-kix_list_27-3>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_22-5 {
  list-style-type: none;
}

ol.lst-kix_list_1-1 {
  list-style-type: none;
}

ul.lst-kix_list_22-6 {
  list-style-type: none;
}

.lst-kix_list_17-5>li {
  counter-increment: lst-ctn-kix_list_17-5;
}

ol.lst-kix_list_1-2 {
  list-style-type: none;
}

ul.lst-kix_list_22-7 {
  list-style-type: none;
}

ol.lst-kix_list_17-0.start {
  counter-reset: lst-ctn-kix_list_17-0 0;
}

.lst-kix_list_27-5>li:before {
  content: "\0025aa   ";
}

ol.lst-kix_list_10-3.start {
  counter-reset: lst-ctn-kix_list_10-3 0;
}

.lst-kix_list_18-6>li:before {
  content: "" counter(lst-ctn-kix_list_18-6, decimal) ". ";
}

ol.lst-kix_list_9-4.start {
  counter-reset: lst-ctn-kix_list_9-4 0;
}

ul.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_3-8 {
  list-style-type: none;
}

.lst-kix_list_10-1>li:before {
  content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ". ";
}

.lst-kix_list_18-4>li:before {
  content: "" counter(lst-ctn-kix_list_18-4, lower-latin) ". ";
}

.lst-kix_list_18-8>li:before {
  content: "" counter(lst-ctn-kix_list_18-8, lower-roman) ". ";
}

ul.lst-kix_list_3-1 {
  list-style-type: none;
}

ul.lst-kix_list_22-8 {
  list-style-type: none;
}

.lst-kix_list_17-6>li {
  counter-increment: lst-ctn-kix_list_17-6;
}

ul.lst-kix_list_3-2 {
  list-style-type: none;
}

.lst-kix_list_7-7>li {
  counter-increment: lst-ctn-kix_list_7-7;
}

ul.lst-kix_list_3-0 {
  list-style-type: none;
}

ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}

ol.lst-kix_list_1-7 {
  list-style-type: none;
}

ul.lst-kix_list_3-5 {
  list-style-type: none;
}

ol.lst-kix_list_1-8 {
  list-style-type: none;
}

ul.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_3-3 {
  list-style-type: none;
}

ul.lst-kix_list_3-4 {
  list-style-type: none;
}

.lst-kix_list_10-7>li:before {
  content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ") ";
}

.lst-kix_list_7-8>li {
  counter-increment: lst-ctn-kix_list_7-8;
}

.lst-kix_list_10-5>li:before {
  content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". ";
}

ol.lst-kix_list_13-5.start {
  counter-reset: lst-ctn-kix_list_13-5 0;
}

ol.lst-kix_list_18-6.start {
  counter-reset: lst-ctn-kix_list_18-6 0;
}

li.li-bullet-1:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

.lst-kix_list_10-3>li:before {
  content: "(" counter(lst-ctn-kix_list_10-3, decimal) ") ";
}

.lst-kix_list_2-6>li {
  counter-increment: lst-ctn-kix_list_2-6;
}

ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}

ol.lst-kix_list_13-8.start {
  counter-reset: lst-ctn-kix_list_13-8 0;
}

.lst-kix_list_11-7>li {
  counter-increment: lst-ctn-kix_list_11-7;
}

.lst-kix_list_9-2>li:before {
  content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ". ";
}

ol.lst-kix_list_5-7.start {
  counter-reset: lst-ctn-kix_list_5-7 0;
}

.lst-kix_list_12-5>li {
  counter-increment: lst-ctn-kix_list_12-5;
}

.lst-kix_list_20-8>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_5-5>li {
  counter-increment: lst-ctn-kix_list_5-5;
}

.lst-kix_list_9-0>li:before {
  content: "" counter(lst-ctn-kix_list_9-0, decimal) ". ";
}

ul.lst-kix_list_21-1 {
  list-style-type: none;
}

ul.lst-kix_list_21-2 {
  list-style-type: none;
}

ul.lst-kix_list_21-3 {
  list-style-type: none;
}

ul.lst-kix_list_21-4 {
  list-style-type: none;
}

.lst-kix_list_20-0>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_21-5 {
  list-style-type: none;
}

ul.lst-kix_list_21-6 {
  list-style-type: none;
}

ul.lst-kix_list_21-7 {
  list-style-type: none;
}

ul.lst-kix_list_21-8 {
  list-style-type: none;
}

ol.lst-kix_list_10-7 {
  list-style-type: none;
}

.lst-kix_list_9-6>li:before {
  content: "" counter(lst-ctn-kix_list_9-6, decimal) ") ";
}

ol.lst-kix_list_10-8 {
  list-style-type: none;
}

ol.lst-kix_list_10-3 {
  list-style-type: none;
}

.lst-kix_list_9-4>li:before {
  content: "" counter(lst-ctn-kix_list_9-4, lower-latin) ". ";
}

ol.lst-kix_list_10-4 {
  list-style-type: none;
}

ol.lst-kix_list_10-5 {
  list-style-type: none;
}

.lst-kix_list_11-3>li:before {
  content: "(" counter(lst-ctn-kix_list_11-3, decimal) ") ";
}

ol.lst-kix_list_10-6 {
  list-style-type: none;
}

ul.lst-kix_list_21-0 {
  list-style-type: none;
}

.lst-kix_list_6-3>li {
  counter-increment: lst-ctn-kix_list_6-3;
}

.lst-kix_list_20-6>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_18-4.start {
  counter-reset: lst-ctn-kix_list_18-4 0;
}

ol.lst-kix_list_10-0 {
  list-style-type: none;
}

ol.lst-kix_list_10-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}

ol.lst-kix_list_10-2 {
  list-style-type: none;
}

ol.lst-kix_list_12-1.start {
  counter-reset: lst-ctn-kix_list_12-1 0;
}

ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}

.lst-kix_list_20-4>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_11-5>li:before {
  content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". ";
}

ol.lst-kix_list_6-1.start {
  counter-reset: lst-ctn-kix_list_6-1 0;
}

.lst-kix_list_20-2>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_9-8>li:before {
  content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ") ";
}

.lst-kix_list_1-1>li:before {
  content: "" counter(lst-ctn-kix_list_1-0, upper-roman) "." counter(lst-ctn-kix_list_1-1, decimal) " ";
}

.lst-kix_list_11-7>li:before {
  content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ") ";
}

.lst-kix_list_8-5>li {
  counter-increment: lst-ctn-kix_list_8-5;
}

.lst-kix_list_1-3>li:before {
  content: "(" counter(lst-ctn-kix_list_1-3, lower-roman) ") ";
}

ol.lst-kix_list_10-5.start {
  counter-reset: lst-ctn-kix_list_10-5 0;
}

.lst-kix_list_4-8>li {
  counter-increment: lst-ctn-kix_list_4-8;
}

.lst-kix_list_1-7>li:before {
  content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ") ";
}

ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}

.lst-kix_list_1-5>li:before {
  content: "(" counter(lst-ctn-kix_list_1-5, decimal) ") ";
}

ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0;
}

.lst-kix_list_5-6>li {
  counter-increment: lst-ctn-kix_list_5-6;
}

ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}

.lst-kix_list_2-1>li:before {
  content: "(" counter(lst-ctn-kix_list_2-1, lower-latin) ") ";
}

.lst-kix_list_27-7>li:before {
  content: "o  ";
}

.lst-kix_list_19-8>li {
  counter-increment: lst-ctn-kix_list_19-8;
}

.lst-kix_list_2-3>li:before {
  content: "(" counter(lst-ctn-kix_list_2-3, decimal) ") ";
}

.lst-kix_list_11-8>li {
  counter-increment: lst-ctn-kix_list_11-8;
}

ol.lst-kix_list_19-6 {
  list-style-type: none;
}

ol.lst-kix_list_19-7 {
  list-style-type: none;
}

ol.lst-kix_list_19-8 {
  list-style-type: none;
}

ol.lst-kix_list_19-2 {
  list-style-type: none;
}

ol.lst-kix_list_19-3 {
  list-style-type: none;
}

ol.lst-kix_list_19-4 {
  list-style-type: none;
}

ol.lst-kix_list_19-5 {
  list-style-type: none;
}

ol.lst-kix_list_19-0 {
  list-style-type: none;
}

.lst-kix_list_9-1>li {
  counter-increment: lst-ctn-kix_list_9-1;
}

ol.lst-kix_list_19-1 {
  list-style-type: none;
}

ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}

.lst-kix_list_3-1>li:before {
  content: "o  ";
}

.lst-kix_list_3-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_26-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_26-7>li:before {
  content: "o  ";
}

.lst-kix_list_8-1>li:before {
  content: "(" counter(lst-ctn-kix_list_8-1, lower-latin) ") ";
}

ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}

ol.lst-kix_list_17-4.start {
  counter-reset: lst-ctn-kix_list_17-4 0;
}

.lst-kix_list_8-2>li:before {
  content: "(" counter(lst-ctn-kix_list_8-2, lower-roman) ") ";
}

.lst-kix_list_6-0>li {
  counter-increment: lst-ctn-kix_list_6-0;
}

.lst-kix_list_3-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_18-0>li {
  counter-increment: lst-ctn-kix_list_18-0;
}

.lst-kix_list_12-0>li {
  counter-increment: lst-ctn-kix_list_12-0;
}

ol.lst-kix_list_12-3.start {
  counter-reset: lst-ctn-kix_list_12-3 0;
}

ol.lst-kix_list_11-5.start {
  counter-reset: lst-ctn-kix_list_11-5 0;
}

.lst-kix_list_21-2>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_8-5>li:before {
  content: " ";
}

.lst-kix_list_11-1>li {
  counter-increment: lst-ctn-kix_list_11-1;
}

.lst-kix_list_8-6>li:before {
  content: " ";
}

.lst-kix_list_2-0>li {
  counter-increment: lst-ctn-kix_list_2-0;
}

.lst-kix_list_26-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_26-3>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_3-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_21-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_21-7>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0;
}

.lst-kix_list_11-2>li:before {
  content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". ";
}

ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}

.lst-kix_list_21-3>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_18-7.start {
  counter-reset: lst-ctn-kix_list_18-7 0;
}

ol.lst-kix_list_11-6.start {
  counter-reset: lst-ctn-kix_list_11-6 0;
}

ol.lst-kix_list_12-4.start {
  counter-reset: lst-ctn-kix_list_12-4 0;
}

.lst-kix_list_16-6>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_10-1.start {
  counter-reset: lst-ctn-kix_list_10-1 0;
}

.lst-kix_list_4-4>li {
  counter-increment: lst-ctn-kix_list_4-4;
}

ol.lst-kix_list_6-4.start {
  counter-reset: lst-ctn-kix_list_6-4 0;
}

.lst-kix_list_25-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_17-1>li:before {
  content: "" counter(lst-ctn-kix_list_17-1, lower-latin) ". ";
}

.lst-kix_list_18-2>li {
  counter-increment: lst-ctn-kix_list_18-2;
}

.lst-kix_list_25-3>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_5-6.start {
  counter-reset: lst-ctn-kix_list_5-6 0;
}

.lst-kix_list_16-1>li:before {
  content: "o  ";
}

ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}

.lst-kix_list_7-3>li {
  counter-increment: lst-ctn-kix_list_7-3;
}

.lst-kix_list_16-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_16-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_19-3>li {
  counter-increment: lst-ctn-kix_list_19-3;
}

ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}

.lst-kix_list_12-4>li {
  counter-increment: lst-ctn-kix_list_12-4;
}

ol.lst-kix_list_11-0.start {
  counter-reset: lst-ctn-kix_list_11-0 0;
}

ol.lst-kix_list_18-8.start {
  counter-reset: lst-ctn-kix_list_18-8 0;
}

ol.lst-kix_list_6-3.start {
  counter-reset: lst-ctn-kix_list_6-3 0;
}

ol.lst-kix_list_10-2.start {
  counter-reset: lst-ctn-kix_list_10-2 0;
}

.lst-kix_list_12-7>li {
  counter-increment: lst-ctn-kix_list_12-7;
}

.lst-kix_list_17-2>li:before {
  content: "" counter(lst-ctn-kix_list_17-2, lower-roman) ". ";
}

ol.lst-kix_list_5-5.start {
  counter-reset: lst-ctn-kix_list_5-5 0;
}

ol.lst-kix_list_17-3.start {
  counter-reset: lst-ctn-kix_list_17-3 0;
}

.lst-kix_list_17-6>li:before {
  content: "" counter(lst-ctn-kix_list_17-6, decimal) ". ";
}

.lst-kix_list_17-5>li:before {
  content: "" counter(lst-ctn-kix_list_17-5, lower-roman) ". ";
}

.lst-kix_list_6-2>li {
  counter-increment: lst-ctn-kix_list_6-2;
}

.lst-kix_list_2-6>li:before {
  content: "" counter(lst-ctn-kix_list_2-6, decimal) ") ";
}

.lst-kix_list_27-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_22-3>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_7-1>li:before {
  content: "" counter(lst-ctn-kix_list_7-1, lower-latin) ". ";
}

.lst-kix_list_7-5>li:before {
  content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". ";
}

.lst-kix_list_13-5>li {
  counter-increment: lst-ctn-kix_list_13-5;
}

.lst-kix_list_9-6>li {
  counter-increment: lst-ctn-kix_list_9-6;
}

.lst-kix_list_27-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_19-5>li {
  counter-increment: lst-ctn-kix_list_19-5;
}

.lst-kix_list_22-7>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_5-4.start {
  counter-reset: lst-ctn-kix_list_5-4 0;
}

ol.lst-kix_list_11-1.start {
  counter-reset: lst-ctn-kix_list_11-1 0;
}

.lst-kix_list_18-5>li:before {
  content: "" counter(lst-ctn-kix_list_18-5, lower-roman) ". ";
}

.lst-kix_list_13-6>li:before {
  content: "" counter(lst-ctn-kix_list_13-6, decimal) ") ";
}

ol.lst-kix_list_5-1.start {
  counter-reset: lst-ctn-kix_list_5-1 0;
}

.lst-kix_list_6-7>li {
  counter-increment: lst-ctn-kix_list_6-7;
}

.lst-kix_list_10-6>li {
  counter-increment: lst-ctn-kix_list_10-6;
}

.lst-kix_list_11-6>li {
  counter-increment: lst-ctn-kix_list_11-6;
}

.lst-kix_list_1-7>li {
  counter-increment: lst-ctn-kix_list_1-7;
}

ol.lst-kix_list_10-0.start {
  counter-reset: lst-ctn-kix_list_10-0 0;
}

.lst-kix_list_7-5>li {
  counter-increment: lst-ctn-kix_list_7-5;
}

.lst-kix_list_15-6>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_17-8.start {
  counter-reset: lst-ctn-kix_list_17-8 0;
}

.lst-kix_list_11-4>li {
  counter-increment: lst-ctn-kix_list_11-4;
}

ol.lst-kix_list_6-8.start {
  counter-reset: lst-ctn-kix_list_6-8 0;
}

.lst-kix_list_10-2>li:before {
  content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ". ";
}

.lst-kix_list_4-6>li {
  counter-increment: lst-ctn-kix_list_4-6;
}

.lst-kix_list_13-7>li {
  counter-increment: lst-ctn-kix_list_13-7;
}

ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}

.lst-kix_list_1-5>li {
  counter-increment: lst-ctn-kix_list_1-5;
}

.lst-kix_list_20-7>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_17-5.start {
  counter-reset: lst-ctn-kix_list_17-5 0;
}

ol.lst-kix_list_6-5.start {
  counter-reset: lst-ctn-kix_list_6-5 0;
}

.lst-kix_list_4-2>li:before {
  content: "(" counter(lst-ctn-kix_list_4-2, lower-latin) ") ";
}

.lst-kix_list_4-6>li:before {
  content: "" counter(lst-ctn-kix_list_4-6, decimal) " ";
}

.lst-kix_list_17-4>li {
  counter-increment: lst-ctn-kix_list_17-4;
}

.lst-kix_list_15-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_10-8>li {
  counter-increment: lst-ctn-kix_list_10-8;
}

.lst-kix_list_25-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_10-6>li:before {
  content: "" counter(lst-ctn-kix_list_10-6, decimal) ") ";
}

.lst-kix_list_9-1>li:before {
  content: "" counter(lst-ctn-kix_list_9-1, lower-latin) ". ";
}

ol.lst-kix_list_12-7.start {
  counter-reset: lst-ctn-kix_list_12-7 0;
}

ol.lst-kix_list_6-7.start {
  counter-reset: lst-ctn-kix_list_6-7 0;
}

.lst-kix_list_12-2>li {
  counter-increment: lst-ctn-kix_list_12-2;
}

.lst-kix_list_9-5>li:before {
  content: "" counter(lst-ctn-kix_list_9-5, lower-roman) ". ";
}

.lst-kix_list_12-2>li:before {
  content: "" counter(lst-ctn-kix_list_12-2, lower-roman) ". ";
}

ol.lst-kix_list_12-8.start {
  counter-reset: lst-ctn-kix_list_12-8 0;
}

.lst-kix_list_11-6>li:before {
  content: "" counter(lst-ctn-kix_list_11-6, decimal) ") ";
}

.lst-kix_list_20-3>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_11-3.start {
  counter-reset: lst-ctn-kix_list_11-3 0;
}

.lst-kix_list_1-2>li:before {
  content: "(" counter(lst-ctn-kix_list_1-2, lower-latin) ") ";
}

.lst-kix_list_10-1>li {
  counter-increment: lst-ctn-kix_list_10-1;
}

.lst-kix_list_1-0>li {
  counter-increment: lst-ctn-kix_list_1-0;
}

.lst-kix_list_8-8>li {
  counter-increment: lst-ctn-kix_list_8-8;
}

ol.lst-kix_list_17-6.start {
  counter-reset: lst-ctn-kix_list_17-6 0;
}

.lst-kix_list_1-6>li:before {
  content: " " counter(lst-ctn-kix_list_1-6, lower-roman) ") ";
}

li.li-bullet-0:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 36pt;
}

.lst-kix_list_18-7>li {
  counter-increment: lst-ctn-kix_list_18-7;
}

.lst-kix_list_12-6>li:before {
  content: "" counter(lst-ctn-kix_list_12-6, decimal) ") ";
}

ol.lst-kix_list_11-4.start {
  counter-reset: lst-ctn-kix_list_11-4 0;
}

.lst-kix_list_2-2>li:before {
  content: "(" counter(lst-ctn-kix_list_2-2, lower-roman) ") ";
}

.lst-kix_list_13-2>li:before {
  content: "" counter(lst-ctn-kix_list_13-2, lower-roman) ". ";
}

ol.lst-kix_list_5-2.start {
  counter-reset: lst-ctn-kix_list_5-2 0;
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c15 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #f2f2f2;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 863.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c11 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 180.6pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c19 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 87.3pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c14 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 195.3pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c13 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c9 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c16 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c5 {
  margin-left: 54pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c20 {
  margin-left: 54pt;
  padding-top: 12pt;
  padding-left: 18pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c21 {
  margin-left: 90pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c3 {
  margin-left: 55pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c2 {
  margin-left: 24.1pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c1 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: italic;
}

.c8 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c7 {
  padding-top: 0pt;
  text-indent: 36pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c25 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c10 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c17 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c23 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  text-align: left;
  height: 11pt;
}

.c12 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}

.c27 {
  max-width: 868pt;
  padding: 72pt 72pt 72pt 72pt;
}

.c4 {
  padding: 0;
  margin: 0;
}

.c24 {
  font-weight: 700;
}

.c18 {
  background-color: #f2f2f2;
}

.c26 {
  page-break-after: avoid;
}

.c28 {
  height: 11pt;
}

.c6 {
  height: 0pt;
}

.c22 {
  background-color: #ffffff;
}

.title {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 12pt;
  font-family: "Calibri";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.subtitle {
  padding-top: 0pt;
  color: #4f81bd;
  font-size: 12pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}

h1 {
  padding-top: 0pt;
  color: #000000;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 0pt;
  color: #000000;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 0pt;
  color: #000000;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 0pt;
  color: #000000;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 0pt;
  color: #000000;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 0pt;
  color: #000000;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
